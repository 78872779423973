<script setup lang="ts">
import { computed, ref } from 'vue'
import { RouterView, useRoute } from 'vue-router'

import Modal from '@/components/Modal.vue'
import { useModal } from '@/modal/useModal'

const { isOpen } = useModal()

const isLeftAsideOpen = ref(true)

// Vite dev hardcoded search api ky
// const fetchSearchKey = () => Promise.resolve('MGUzNTQxOWNjMjU5NTg2YjcwZjY2NjUwMmMyNTljYTRhODJlNzc5NjQ4ZjZmMzdmNWZhZjkyMjA4NTM2ODc5NGZpbHRlcnM9dmlzaWJsZV9ieSUzQWFsbA')

// tailwindcss needs to include classes and build them dynamically; so we list possible dynamic ones here (instead of config 'safelist')
const _classesToInclude = [
  'md:col-start-1',
  'md:col-start-2',
  'md:col-start-3',
  'md:col-start-4',
  'md:row-start-1',
  'md:row-start-2',
  'md:row-start-3',
  'md:row-start-4',
  'md:row-start-5',
  'md:col-end-1',
  'md:col-end-2',
  'md:col-end-3',
  'md:col-end-4',
  'md:row-end-1',
  'md:row-end-2',
  'md:row-end-3',
  'md:row-end-4',
  'md:row-end-5',
]
// FIXME: fix the silly error -- ignore in eslint?
_classesToInclude

/**
 * Test to see if component is not named/configured.
 */
function no(component: string) {
  return !components.value.has(component)
}

/**
 * Convert the configured component names in the router to a set.
 */
const components = computed(() => {
  const route = useRoute()
  // flatmap out the names of the components needed.
  // pop them into a Set<> for uniquenes.
  return new Set(route.matched.flatMap((c) => (c?.components ? Object.keys(c.components) : [])))
})

/**
 * modify the grid for "main"
 */
const stretchMain = computed(() => {
  let rowStart = 3
  let rowEnd = 4
  let colStart = 2
  let colEnd = 3

  // if there is no "nav", start a row higher
  if (no('nav')) rowStart--
  // if there is no "header", start a row higher
  if (no('header')) rowStart--
  // if there is no "footer", stretch down.
  if (no('footer')) rowEnd++
  // left
  if (no('leftAside')) colStart--
  // right
  if (no('rightAside')) colEnd++

  // { 'grid-area': `${rowStart} / ${colStart} / ${rowEnd} / ${colEnd}` }
  return [`md:row-start-${rowStart}`, `md:col-start-${colStart}`, `md:row-end-${rowEnd}`, `md:col-end-${colEnd}`]
})

/**
 * Grow the asides
 */
const stretchAside = computed(() => {
  let rowStart = 2
  let rowEnd = 4

  // if there is no "header", stretch up.
  if (no('header')) rowStart--
  // if there is no "footer", stretch down.
  if (no('footer')) rowEnd++

  // { 'grid-row': `${rowStart} / ${rowEnd}` }
  return [`md:row-start-${rowStart}`, `md:row-end-${rowEnd}`]
})

/**
 * Toggle left aside
 */
const toggleLeftAside = computed(() => {
  if (no('leftAside')) return 'md:grid-cols-[minmax(10px,1fr)_minmax(10px,4fr)_minmax(10px,1fr)]'
  return isLeftAsideOpen.value
    ? 'md:grid-cols-[16vmax_minmax(10px,4fr)_minmax(10px,1fr)]'
    : 'md:grid-cols-[0_minmax(10px,4fr)_minmax(10px,1fr)]'
})
</script>

<!--
  The 3-ish column holy grail. Has:

      1         2                  3         4 (col)
   1  ----------------------------------------
      |   Header                             |
   2  +--------------------------------------+
      | Left    |   NAV            | Right   |
   3  | Context |----------------- | Context |
      |         |                  |         |
      |         |   Main           |         |
      |         |                  |         |
      |         |                  |         |
   4  +--------------------------------------+
      |   Footer                             |
   5  ----------------------------------------
 (row)

 And on smaller breakpoint:

   1  ----------------------------------------
      |   Header                             |
   2  +--------------------------------------+
      |   NAV                                |
   3  +--------------------------------------+
      |   Left Context                       |
   4  +--------------------------------------+
      |   Right Context                      |
   5  +--------------------------------------+
      |   Main                               |
      |   Main                               |
      |   Main                               |
   6  +--------------------------------------+
      |   Footer                             |
   7  +--------------------------------------+



  0. First is for medium screen and larger; otherwise turns to rows.
  1. The router to controls the content of all the sections;
  2. Defined components result in the element showing.
  3. The "main" block is the content section -- required.
  4. The grid shape never changes -- elements stretch to fill in spots that are not defined.
  5. When in a small breakpoint, there is no need to re-span rows because they are set to 'min-content'
-->

<template>
  <div>
    <Modal v-show="isOpen">
      <div id="modal-container"></div>
    </Modal>

    <!-- Wrap the Search components -->
    <ais-instant-search index-name="global" v-bind:search-client="$searchClient">
      <ais-configure filters="NOT tags:tombstone" />
      <div
        class="page-wrap grid h-screen transition-all duration-300 ease-in-out grid-cols-[1fr] grid-rows-[min-content_1fr]"
        :class="toggleLeftAside"
      >
        <header
          v-if="components.has('header')"
          class="page-header col-span-full row-start-1 md:col-start-1 md:col-end-4"
        >
          <RouterView name="header" />
        </header>
        <main class="page-main bg-white dark:bg-gray-900" :class="stretchMain">
          <RouterView name="main" />
        </main>
        <aside
          v-if="components.has('leftAside')"
          class="page-left-sidebar col-span-full row-start-3 md:col-start-1 md:col-end-2"
          :class="stretchAside"
          aria-label="leftAside"
        >
          <RouterView name="leftAside" @toggle="isLeftAsideOpen = !isLeftAsideOpen" />
        </aside>
        <aside
          v-if="components.has('rightAside')"
          class="page-right-sidebar col-span-full row-start-4 md:col-start-3 md:col-end-4"
          :class="stretchAside"
          aria-label="rightAside"
        >
          <RouterView name="rightAside" />
        </aside>
      </div>
    </ais-instant-search>
  </div>
</template>
